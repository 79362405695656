jQuery(function() {
  if ($('#table-supertutorial_slides').length > 0) {
    return $('#table-supertutorial_slides').sortable({
      axis: 'y',
      items: '.item',
      cursor: 'move',
      sort: function(e, ui) {
        return ui.item.addClass('active-item-shadow');
      },
      stop: function(e, ui) {
        ui.item.removeClass('active-item-shadow');
        return ui.item.children('td').effect('highlight', {}, 1000);
      },
      update: function(e, ui) {
        var position, slide_id, tutorial_id;
        slide_id = ui.item.data('slide-id');
        tutorial_id = ui.item.data('tutorial-id');
        position = ui.item.index();
        return $.ajax({
          type: 'PUT',
          url: '/r/superadmin/tutorials/' + tutorial_id + '/slides/' + slide_id,
          data: {
            superadmin_tutorial_slide: {
              row_order_position: position
            }
          }
        });
      }
    });
  }
});
