import '../src/shared/bootstrap341.min.js'
import 'controllers'

//import Rails from "@rails/ujs"

// Make accessible for Electron and Mobile adapters
//window.Rails = Rails

require("@rails/activestorage").start()
// require("channels")
// require("trix")
// require("@rails/actiontext")

// css
import 'stylesheets/superadmin/base.scss'

import '../src/shared/hashcash.js'
import '../src/shared/jquery-ujs.js'
import '../src/shared/active_storage_direct_upload.js'
import '../src/shared/auto_numeric_min.js'
import '../src/shared/sweetalert_confirm_modal.js'
import '../src/shared/jquery-ui.min.js'
import '../src/shared/offline.min.js'

import '../src/superadmin/tutorial_slides.js'

// tinymce
import tinymce from 'tinymce/tinymce'
import 'tinymce-i18n/langs5/pt_BR'
import 'tinymce/icons/default'
import 'tinymce/themes/silver'
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/link'
import 'tinymce/plugins/image'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/hr'
import 'tinymce/plugins/visualblocks'
import 'tinymce/plugins/code'
import 'tinymce/plugins/media'
import 'tinymce/plugins/save'
import 'tinymce/plugins/table'
import 'tinymce/plugins/contextmenu'
import 'tinymce/plugins/directionality'
import 'tinymce/plugins/emoticons'
import 'tinymce/plugins/emoticons/js/emojis'
import 'tinymce/plugins/paste'
import 'tinymce/plugins/textcolor'
import 'tinymce/plugins/colorpicker'
import 'tinymce/plugins/textpattern'
import 'tinymce/plugins/imagetools'

require("chartkick")
require("chart.js")

//Rails.start()

$( function() {
    
  $('input[data-role=money]').autoNumeric('init', { aSep: '', aDec: ',' } );
  
  console.log('boxloja loaded v8');

});